import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { NextSpinInfo } from '@shared/types/next-spin-info';
import { Spin } from '@shared/types/spin';
import {
  Observable,
  BehaviorSubject,
  tap,
  throwError,
  catchError,
  map,
  timer,
} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SpinService {
  private _spin = new BehaviorSubject<Spin | undefined>(undefined);
  private _spinInfo = new BehaviorSubject<NextSpinInfo | undefined>(undefined);
  private _showNextSpinInfo = new BehaviorSubject<boolean>(false);

  readonly spin$ = this._spin.asObservable();
  readonly spinInfo$ = this._spinInfo.asObservable();
  readonly showNextSpinInfo$ = this._showNextSpinInfo.asObservable();
  readonly allowedToSpin$ = this.spinInfo$.pipe(
    map((info) => !!info?.allowedToSpin),
  );

  constructor(private http: HttpClient) {}

  fetchSpinInfo(gameUuid: string): Observable<NextSpinInfo> {
    return this.http
      .get<NextSpinInfo>(environment.backendUrl + '/api/wheel/spin/' + gameUuid)
      .pipe(
        tap((data) => {
          this.setSpinInfo({
            ...data,
            startTs: new Date().getTime(),
          });

          this.updateAllowedToSpinAfterDelay(data.nextSpinInSeconds);
        }),
      );
  }

  onSpin(gameUuid: string): Observable<Spin> {
    return this.http
      .post<Spin>(`${environment.backendUrl}/api/wheel/spin/${gameUuid}`, {})
      .pipe(
        tap((spin: Spin) => {
          this.setSpin(spin);
          this.setSpinInfo({
            ...spin.nextSpinInfo,
            startTs: new Date().getTime(),
          });
          this.updateAllowedToSpinAfterDelay(
            spin.nextSpinInfo.nextSpinInSeconds,
          );
        }),
        catchError((error: Error) => {
          return throwError(() => new Error(error.message));
        }),
      );
  }

  setSpinInfo(info: NextSpinInfo) {
    this._spinInfo.next(info);
  }

  updateShowNextSpinInfo(data: boolean) {
    this._showNextSpinInfo.next(data);
  }

  setSpin(data: Spin | undefined): void {
    this._spin.next(data);
  }

  private updateAllowedToSpinAfterDelay(seconds: number): void {
    if (seconds <= 0) {
      this.setSpinInfo({
        ...this._spinInfo.getValue()!,
        allowedToSpin: true,
      });
    } else {
      timer(seconds * 1000).subscribe(() => {
        const currentInfo = this._spinInfo.getValue();
        if (currentInfo) {
          this.setSpinInfo({
            ...currentInfo,
            allowedToSpin: true,
          });
        }
      });
    }
  }
}
