import { Injectable } from '@angular/core';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  public static readonly supportedLanguages = [
    'en',
    'hr',
    'de',
    'es',
    'pt',
    'fr',
    'sv',
  ];

  constructor(private session: SessionService) {}

  translateArgs(key: string, ...args: string[]): string {
    try {
      return this.replaceArgsInTranslation(this.getTranslation(key), ...args);
    } catch (_err) {
      return key;
    }
  }

  translate(key: string): string {
    try {
      return this.getTranslation(key);
    } catch (_err) {
      return key;
    }
  }

  private getTranslation(keyString: string): string {
    if (!this.session.getThemeLabels()) return '';
    return (
      this.session
        .getThemeLabels()!
        .find((label: { key: string }) => label.key === keyString)?.value ?? ''
    );
  }

  private replaceArgsInTranslation(str: string, ...args: string[]) {
    if (args.length === 0 || !str) {
      return '';
    }
    const mapObj: Record<string, string> = {};
    args.forEach((arg, idx) => (mapObj['#args' + idx] = arg));
    const regExp = new RegExp(Object.keys(mapObj).join('|'), 'gi');
    return str.replace(regExp, (matched) => mapObj[matched.toLowerCase()]);
  }
}
